@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700);
html,body{margin:0;padding:0;height:100%;font-family:"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{display:flex;justify-content:center}h1{margin:0}h2{margin:0}h3{margin:0}h4{margin:0}h5{margin:0}svg g g g rect{cursor:pointer}table tbody tr[class^=MuiTableRow-root-]:not([style="height: 1px;"]){height:2rem !important}.mainContent{margin-top:50px}#sidebar{height:100%;height:-webkit-fill-available}#CustomButton-button:hover{background:#fafafa !important;box-shadow:0 .08rem .3rem rgba(0,0,0,.3333333333) !important}#linearProgressBar{width:100%;width:-webkit-fill-available;width:-moz-available}.noTextOverflow{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.notFavoriteIcon:hover{filter:drop-shadow(0 1px 0 #000)}
.MuiTableCell-root{padding:0 1.1rem 0 1.1rem !important}.MuiList-root .MuiButtonBase-root{display:flex !important}
.fullscreen-enabled{display:flex;justify-content:center;align-items:center}
.water-title {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 9%;
	top: 72.6%;
}
.warm-water {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 9%;
	top: 77.5%;
	font-style: italic;
}
.coldwater {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 14.2%;
	top: 77.5%;
	font-style: italic;
}
.week-water {
	position: absolute;
	left: 4.2%;
	top: 80.4%;
	font-weight: 300;
	color: #fffc;
}

.month-water {
	position: absolute;
	left: 4.2%;
	top: 85.3%;
	font-weight: 300;
	color: #fffc;
}
.year-water {
	position: absolute;
	left: 4.2%;
	top: 90.2%;
	font-weight: 300;
	color: #fffc;
}
.waterData-container {
	position: absolute;
	margin-right: 6px;
	left: 8.8%;
	top: 80.5%;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
}
.data2-container {
	position: absolute;
	margin-right: 6px;
	left: 14%;
	top: 80.5%;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
}

.week-water-unit {
	position: absolute;
	left: 10.8%;
	top: 80.1%;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
}
.month-water-unit {
	position: absolute;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
	left: 10.8%;
	top: 84.9%;
}

.year-water-unit {
	position: absolute;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
	left: 10.8%;
	top: 89.6%;
}
.year-second-unit {
	position: absolute;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
	left: 16%;
	top: 89.6%;
}
.month-second-unit {
	position: absolute;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
	left: 16%;
	top: 84.9%;
}
.week-second-unit {
	position: absolute;
	left: 16%;
	top: 80%;
	font-weight: 300;
	color: rgba(187, 185, 185, 0.808);
}
.temperature-title {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 26.8%;
	top: 72.6%;
}
.temperature-inside {
	color: #fffc;

	font-size: 16px;
	margin-right: 8px;
}
.temperature-outside {
	color: #fffc;

	font-size: 16px;
	margin-right: 6px;
}
.temperature-unit {
	color: rgba(187, 185, 185, 0.808);

	font-size: 16px;
	margin-right: 16px;
}
.temperature-unit2 {
	color: rgba(187, 185, 185, 0.808);
}
.electricity-title {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 49.4%;
	top: 72.6%;
}

.heating-title {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 67.5%;
	top: 72.6%;
}
.solar-title {
	position: absolute;
	font-weight: 400;
	color: #fffc;
	left: 86.6%;
	top: 72.6%;
}
.row-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;

	font-weight: 300;
	color: #fffc;
	transform: translate(0, -50%);
	top: 88%;
}
.data-container {
	color: rgba(187, 185, 185, 0.808);

	margin-right: 2px;
}
.time-container {
	font-size: 16px;
	margin-right: 38px;
}
.watertime-container {
	font-size: 16px;
	margin-right: 16px;
}
.data2-container {
	margin-right: 6px;
}
.unit-container {
	color: rgba(187, 185, 185, 0.808);

	font-size: 16px;
	margin-right: 14px;
}
.temp1 {
	color: rgba(187, 185, 185, 0.808);

	font-size: 16px;
	margin-right: 2px;
}
.temp2 {
	color: rgba(187, 185, 185, 0.808);

	font-size: 16px;
	margin-right: 2px;
}
.linechart-container {
	position: absolute;
	width: 45%;
	height: 60%;
	top: 0%;
	left: 0%;
}
.linechart-unit {
	position: absolute;
	font-weight: 100;
	color: #fffc;
	left: 2%;
	top: 0%;
}
.linechart-title {
	position: absolute;
	font-weight: 300;
	color: #fffc;
	left: 35%;
	top: 0%;
}
.line-chart {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 10%;
}
.energyBarChart-continer {
	position: absolute;
	width: 33%;
	height: 25%;
	top: 0%;
	left: 47%;
}
.energyBarChart {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0%;
	top: 16%;
}
.energyBarChart-title {
	position: absolute;
	font-weight: 300;
	color: #fffc;
	left: 47%;
	top: 0%;
}
.energyBarChart-unit {
	position: absolute;
	font-weight: 100;
	color: #fffc;
	left: 3%;
	top: 0%;
}
.heatingBarChart-container {
	position: absolute;
	width: 33%;
	height: 29%;
	top: 32.5%;
	left: 47%;
}
.heatingBarChart {
	position: absolute;
	width: 100%;
	height: 90%;
	left: 0;
	top: 15.3%;
}
.heatingBarChart-title {
	position: absolute;
	font-weight: 300;
	color: #fffc;
	left: 43.5%;
	top: 0%;
}
.heatingBarChart-unit {
	position: absolute;
	font-weight: 100;
	color: #fffc;
	left: 3%;
	top: 0%;
}
.weather-chart {
	position: absolute;
	z-index: 1;
	width: 14%;
	height: 17%;
	top: 81%;
	left: 23.5%;
}
.weather-container {
	display: relative;
	z-index: 2;
	justify-content: space-between;
	align-items: center;
	position: absolute;
}
.speedometer {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0%;
	top: 10%;
	width: 100%;
	height: 100%;
}
.speedometer-nail {
	position: absolute;
	top: 14%;
	bottom: 0%;
	left: 47%;
	width: 100%;
	height: 80%;
}
.speedometer-container {
	position: absolute;
	width: 18%;
	height: 20%;
	top: 43%;
	right: 1.5%;
}
.speedometer-title {
	font-weight: 300;
	color: #fffc;
	position: absolute;
	width: 18%;
	height: 20%;
	left: 87.5%;
	top: 40%;
}
.speedometer-value {
  font-weight: 300;
  color: #fffc;
  position: absolute;
  width: 18%; 
  height: 20%; 
  left: 88.3%;
  top: 61%;
  z-index: 10;
}

