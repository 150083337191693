@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family:
		'Roboto',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	display: flex;
	justify-content: center;
}

h1 {
	margin: 0;
}

h2 {
	margin: 0;
}

h3 {
	margin: 0;
}

h4 {
	margin: 0;
}

h5 {
	margin: 0;
}

// Nivo ResponsiveBar bars
svg g g g rect {
	cursor: pointer;
}

// material-table rows - only change height here as it's the only way to set the height of empty rows
table tbody tr[class^='MuiTableRow-root-']:not([style='height: 1px;']) {
	height: 2rem !important;
}

// ::placeholder {
// 	opacity: 1 !important;
// }

// -------------------------------------
// Custom styling for imported libraries
// -------------------------------------

// ---------------------------------------
// Custom styling for custom-made elements
// ---------------------------------------

.mainContent {
	margin-top: 50px;
}

#sidebar {
	height: 100%;
	height: -webkit-fill-available;
}

#CustomButton-button:hover {
	background: #fafafa !important;
	box-shadow: 0 0.08rem 0.3rem #0005 !important;
}

// Use '-webkit-fill-available' and  '-moz-available' instead of '100%' for height as 100% renders too much sometimes
// If a later width is not erronous in the browser it will overwrite the one mentioned before
#linearProgressBar {
	width: 100%;
	width: -webkit-fill-available;
	width: -moz-available;
}

.noTextOverflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.notFavoriteIcon:hover {
	filter: drop-shadow(0 1px 0 #000);
}
